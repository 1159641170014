import React from 'react';
import './SolutionPop.less'
interface Props {
    closePop?: (nav: string, type: number) => void
}

const SolutionPop: React.FC<Props> = ({ closePop }) => {
    const linkHandle = (type: number) => {
        closePop?.('solution', type)
    }
    return (
        <div className='product_tooltips'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className='product_item'
                 onClick={() => {
                   linkHandle(6)
                 }}
            >
              <div className='product_item_icon xiaofeipin'></div>
              <div>
                <div className='product_item_title'>消费品</div>
                <div className='product_item_desc'>机会挖掘 | 产品创新 | 渠道研究</div>
                <div className='product_item_desc'>品牌与广告研究 | 消费者研究</div>
              </div>
            </div>
            <div className='product_item'
                 onClick={() => {
                   linkHandle(1)
                 }}
            >
              <div className='product_item_icon industry_icon1'></div>
              <div>
                <div className='product_item_title'>金融行业</div>
                <div className='product_item_desc'>银行｜互联网金融｜保险｜证券</div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' , marginBottom: 16, marginTop: 16}}>
            <div className='product_item'
                 onClick={() => {
                   linkHandle(5)
                 }}
            >
              <div className='product_item_icon industry_icon5'></div>

              <div>
                <div className='product_item_title'>汽车行业</div>
                <div className='product_item_desc'>车型上市前｜车型上市中</div>
                <div className='product_item_desc'>车型上市后</div>
              </div>
            </div>
            <div className='product_item'
                 onClick={() => {
                   linkHandle(4)
                 }}
            >
              <div className='product_item_icon industry_icon4'></div>
              <div>
                <div className='product_item_title'>广告监测</div>
                <div className='product_item_desc'>投放执行｜监播与效果评估</div>
                <div className='product_item_desc'>投放计划｜策略优化</div>
              </div>
            </div>

          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' ,marginBottom:16}}>

            <div className='product_item'
                 onClick={() => {
                   linkHandle(3)
                 }}
            >
              <div className='product_item_icon industry_icon2'></div>
              <div>
                <div className='product_item_title'>物业地产</div>
                <div className='product_item_desc'>满意度数字化 | 增值业务创新</div>
              </div>
            </div>

            {/*<div className='product_item'*/}
            {/*     onClick={() => { linkHandle(2) }}*/}

            {/*>*/}
            {/*  <div className='product_item_icon industry_icon3'></div>*/}
            {/*  <div>*/}
            {/*    <div className='product_item_title'>数字政务</div>*/}
            {/*    <div className='product_item_desc'>全国经济/人口/风险普查｜农业农村｜</div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>

            <div className='product_item_special'
                onClick={() => { linkHandle(2) }}
            >
                <div className='product_item_icon industry_icon3'></div>
                <div>
                    <div className='product_item_title'>数字政务</div>
                    <div className='product_item_special_desc'>
                        <span>全国经济/人口/风险普查｜农业农村｜知识产权｜文旅体育｜食品安全</span>
                        <span>营商环境｜民政人社</span>
                    </div>
                </div>
            </div>




        </div>
    );
};

export default SolutionPop;