export const setCookie = (name: string, value: any) => {
  // 设置cookie过期时间为1小时
  var expires = new Date();
  expires.setTime(expires.getTime() + 3 * 24 * 60 * 60 * 1000);
  // 存储token到cookie
  document.cookie =
    name +
    "=" +
    encodeURIComponent(value) +
    ";expires=" +
    expires.toUTCString() +
    ";path=/";
};

export const getCookie = (name: string) => {
  var value = document.cookie;
  var parts = value?.split("; ");
  if (parts && parts.length > 0 && parts.findIndex((o) => o.indexOf(`${name}=`) > -1)) {
    const index = parts.findIndex((o) => o.indexOf(`${name}=`) > -1);
    let str = index > -1 ? parts[index]?.split("=")[1] : undefined;
    if (str) {
      str = decodeURIComponent(str);
    }
    return str || undefined;
  }
};

export const clearAllCookies = () => {
  let cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
};

export const getParamByName = (name: string, url?: string) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const getHeaderHeight = () => {
  return getProportion() * 44;
};

export const getProportion = () => {
  const clientWidth =
    document.documentElement.clientWidth || document.body.clientWidth;
  return clientWidth / 375;
};
