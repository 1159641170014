import Index from "../pages/Index";
import H5Index from "../pages/H5Index";
export interface RouteItem {
  path: any;
  element: any;
  children?: RouteItem[];
  name: string;
  index?: boolean
  h5Element?: any
}

const routerData: RouteItem[] = [
  {
    path: "/",
    index: true,
    element: Index,
    h5Element: H5Index,
    name: "首页",
    children: [
      {
        path: "home",
        element: 'Layout/Layout',
        h5Element: 'Layout/Layout',
        name: "首页",
        children: [
          {
            index: true,
            path: "",
            element: "Home/Home",
            h5Element: "Home/H5Home",
            name: "首页",
          },
          {
            path: "infoDetail",
            element: "ReportDetail/ReportDetail",
            h5Element: "ReportDetail/H5ReportDetail",
            name: "资讯详情",
          }
        ]
      },
      {
        path: "solution",
        element: "Solution/Solution",
        h5Element: "Solution/H5Solution",
        name: "行业解决方案",
      },
      {
        path: "aboutUs",
        element: "Layout/Layout",
        h5Element: "Layout/Layout",
        name: "关于我们",
        children: [
          {
            index: true,
            path: "",
            element: "AboutUs/index",
            h5Element: "AboutUs/H5Index",
            name: "关于我们",
          },
          {
            path: "connectUs",
            element: "ConnectUs/index",
            h5Element: "ConnectUs/H5Index",
            name: "联系我们",
          },
          {
            path: "jobOffers",
            element: "JobOffers/index",
            h5Element: "JobOffers/H5Index",
            name: "招聘信息",
          },
          {
            path: "purchaseInfo",
            element: "PurchaseInfo/index",
            h5Element: "PurchaseInfo/H5Index",
            name: "采购信息",
          },
        ]
      },
      {
        path: "research",
        element: "Academy/index",
        h5Element: "Academy/H5Index",
        name: "研究院",
      },
      {
        path: "report",
        element: "Layout/Layout",
        h5Element: "Layout/Layout",
        name: '报告',
        children: [
          {
            index: true,
            path: "",
            element: "Report/Report",
            h5Element: "Report/H5Report",
            name: "报告",
          },
          {
            path: "detail",
            element: "ReportDetail/ReportDetail",
            h5Element: "ReportDetail/H5ReportDetail",
            name: "报告详情",
          },
        ]
      },
      {
        path: "production",
        h5Element: "Production/H5Production",
        element: "Production/Production",
        name: "产品",
      },
    ],
  },
  {
    path: "/aboutCem",
    element: "AboutCem/AboutCem",
    h5Element: "AboutCem/H5AboutCem",
    name: "体验宝介绍",
  },
  {
    path: "/login",
    element: "Login/Login",
    h5Element: 'Login/H5Login',
    name: "登录",
  },
  {
    path: "/protocal",
    element: "Layout/Layout",
    h5Element: "Layout/Layout",
    name: "协议",
    children: [
      {
        path: "privacy",
        element: "Protocal/Privacy",
        h5Element: "Protocal/Privacy",
        name: "隐私协议",
      },
      {
        path: "service",
        element: "Protocal/Service",
        h5Element: "Protocal/Service",
        name: "用户协议",
      },
    ]
  },

];

export default routerData;
