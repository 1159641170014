import React from "react";
import { baseImgUrl } from "../../config";

import "./index.less";
interface Props {
  closePop?: (nav: string, anchor?: string) => {}
}
const AboutUsModal: React.FC<Props> = ({ closePop }) => {
  const list = [
    {
      title: "联系我们",
      img: `${baseImgUrl}connect-us-phone.png`,
      href: "/aboutUs/connectUs",
    },
    {
      title: "招聘信息",
      img: `${baseImgUrl}about-us-modal-zhaopin.png`,
      href: "/aboutUs/jobOffers",
    },
    {
      title: "采购信息",
      img: `${baseImgUrl}about-us-modal-car.png`,
      href: "/aboutUs/purchaseInfo",
    },
  ];


  const linkHandle = (nav: string, anchor?: string) => {
    closePop?.(nav, anchor)
  }

  return (
    <div className="about_us_modal">
      <div className="about_us_modal_left">
        <div className="about_us_modal_title">公司介绍</div>
        <div className="about_us_modal_text" onClick={() => linkHandle('aboutUs', 'pinpaigushi')}>
          品牌故事
        </div>
        <div className="about_us_modal_text" onClick={() => linkHandle('aboutUs', 'qiyewenhua')}>
          企业文化
        </div>
        <div className="about_us_modal_text" onClick={() => linkHandle('aboutUs', 'hexingaoguan')}>
          管理团队
        </div>
        <div className="about_us_modal_text" onClick={() => linkHandle('aboutUs', 'zizhirongyu')}>
          资质荣誉
        </div>
      </div>
      <div className="about_us_modal_right">
        {list.map((item, index) => (
          <div
            key={index}
            className="about_us_modal_card"
            onClick={() => linkHandle(item.href)}
          >
            <img src={item.img} alt="" />
            <div>{item.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutUsModal;
