import React, { useEffect, useState } from 'react';
import './ActionBar.less'
import { Popover } from 'antd'
import { baseImgUrl } from '../../config';
interface Props {
    // Props definition
}

const ActionBar: React.FC<Props> = () => {
    const [isVisible, setIsVisible] = useState(false);

    // 监听页面滚动事件，当页面滚动超过一屏时，显示返回顶部按钮
    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > window.innerHeight) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // 处理点击事件，缓慢滚动回到顶部
    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const content = () => {
        return <div className='action_code_box'>
            <div className='action_code'><img src={`${baseImgUrl}acount_code.jpg`} alt='' /></div>
            <div className='action_code_desc'>关注公众号获取行业动态</div>
        </div>
    }

    return (
        <div className='action_container'>
            {/* <div className='action_item active_item'>
                <i className='action_service' />
            </div> */}
            <div className='action_item'>
                <Popover content={content} placement='left' >
                    <i className='action_code' />
                </Popover>
            </div>
            <div className='action_item'
                onClick={() => {
                    window.open("https://forms.zohopublic.com.cn/mkt/form/Untitled/formperma/KeH6KcHfNdHUoTomQCdlBBaR4xGpe0TJG_frBVEip6I")
                }}
            >
                <Popover content={<div>资讯订阅</div>} placement='left' >
                    <i className='action_dingyue' />
                </Popover>

            </div>
            <div style={{ width: 64, height: 64 }}>
                {isVisible && <div className='action_item'
                    onClick={handleClick}
                >
                    <i className='action_backup' />
                </div>}
            </div>
        </div>
    );
};

export default ActionBar;