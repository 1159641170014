import React from "react";
import { baseImgUrl } from "../../config";

import "./Footer.less";
import { useNavigate } from "react-router-dom";
interface Item {
  title?: string
  children?: Item[] | null
  href?: string
  type?: any
}
const Footer = () => {
  const navigate = useNavigate();
  const list: Item[] = [
    {
      title: "产品",
      children: [{ title: "体验宝CEM", href: "https://cempro.cn" }],
      href: "/aboutCem",
    },
    {
      title: "行业解决方案",
      children: [
        { title: "消费品", href: "/solution", type: 6 },
        { title: "金融行业", href: "/solution", type: 1 },
        { title: "汽车行业", href: "/solution", type: 5 },
        { title: "广告监测", href: "/solution", type: 4 },
        { title: "物业地产", href: "/solution", type: 3 },
        { title: "数字政务", href: "/solution", type: 2 },
      ],
      href: "/solution",
    },
    {
      title: "研究院",
      children: [
        { title: "Dtalk数享会", href: "/research", type: 'dTalk' },
        { title: "XM wiki", href: "/research", type: 'xmWiki' },
        { title: "Insight LAB", href: "/research", type: 'insightLab' },
        // { title: "产学研", href: "/research" },
      ],
      href: "/research",
    },
    {
      title: "关于我们",
      children: [
        { title: "公司介绍", href: "/" },
        { title: "联系我们", href: "/aboutUs/connectUs" },
        { title: "招聘信息", href: "/aboutUs/jobOffers" },
        { title: "采购信息", href: "/aboutUs/purchaseInfo" },
      ],
      href: "/aboutUs",
    },
    {
      title: "特别说明",
      children: [
        // { title: "版权说明", href: "" },
        { title: "隐私政策", href: "/protocal/privacy" },
      ],
      href: "/",
    },
    { title: "400 6875 100", children: null, href: '' },
  ];
  const linkHandle = (href: any, type?: any) => {
    if (!href) {
      return
    }
    if (href?.includes('http')) {
      // window.open(href)
      window.location.href=href
    } else {
      if (type) {
        navigate(`${href}?type=${type}`)
      } else {
        navigate(href)

      }

    }
  }
  return (
    <div className="footer_container">
      <div className="footer_container_top">
        <div className="footer_container_main">
          <div className="footer_container_logo"></div>
          <div className="footer_container_horizontal"></div>
          <div className="footer_container_directory">
            {list.map((item, index) => (
              <div key={index}>
                <div
                  className="directory_title"
                  onClick={() => {
                    linkHandle(item.href)
                  }}
                >
                  {!item.children && (
                    <img src={`${baseImgUrl}contact/icon_phone.png`} alt="" />
                  )}
                  <span>{item.title}</span>
                </div>
                {item.children?.map((recode, idx) => (
                  <div
                    key={idx}
                    className="directory_child"
                    onClick={() => {
                      if (recode?.type) {
                        linkHandle(recode.href, recode.type)
                      } else {
                        linkHandle(recode.href)
                      }
                    }}
                  >
                    {recode.title}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <a
        href="https://beian.miit.gov.cn/"
        target="_blank"
        rel="noreferrer"
        className="footer_container_bottom"
      >
        版权声明：Copyright 2016-{new Date().getFullYear()}. BeiJing DATA 100
        information technology Co. Ltd. All Rights Reserved. 京ICP备12038410号
      </a>
    </div>
  );
};

export default Footer;
