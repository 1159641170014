export const list = [
  {
    anchor:'dTalk',
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAABJlBMVEUAAACxT/+XNv96D/97Dv+lPP9xBP93DP+7WvqRKP2GHf6JIf2NIv6jPvyWL/2hP/++XfmLI/2oQ/xyBf+tSftwA/+AF/+7WPvAYPetSfuAFP6NJP27Wfq/XvmcNP29W/qgOfyOJP2+XPpxBf+zUPp3DP6MJP2+XPmYMvuXL/y5V/m9W/m+XfjAXft1Cf62VPpwA/6IH/2ZMvyRKvyoRPqSKv2mQft/Ff6hO/ykQPp1Cv2DGv10Bv+zUPuaMvxwBP+nQvuOJv2qRfuDGP6JIP1+E/6zT/q2UvqfOPycNfyaM/y9Wvp4DP97EP6UK/2YMPx2Cf+vS/uHHP6RKf2LIv26WPqjPfxzB/9xA/+BFv6tSfu4VfqhO/ylP/y/XvmWLv2yT/u1UvnmFTmcAAAAQHRSTlMABwUuJgzq1Yh7dG80JRoT99bSwpRNRjcf9Onn5N7R0MW/urKwqqajjm9uVk48+fn19fX169zZt6iglIiAeVFACV6LdQAAAj1JREFUSMfN1td2qkAUBmDUxBa7Jqb3ntN7P9hAHcWKoIjl/V/izCCjA561B3OVff+t/9971hKFlzA+/3PUblbXtTc/AhuyQBAzrd1uNEKJjeJ06prNyp53t8W6ynDHMwxiuHLD0k+P7q/Llbq33uAV60rYlcsXnl5G19yu3E9v851/5SrEdbErFI6SfMjmUVfofYzzj8rmlYkjUJnGePCG3nPlej1Fmbb+cGDMmdenrl5/4MgQyXP2VFrY1Wr3MNyye3ZJ3mK/qeWq1W+w3Bu6erZsNx6f+2DJ5LFuMDC/bIMywNxzyjhRFD8l4T3fM3msKxYPEnDmEWEOZ4oWHB3AbX+lMXT0NBeu07mGy2Yv1ntaTpLioMyGbllnUjeTpDAId7Xg/VreCDNZfgt3bWvvIvQupu062M1lA4SJRlvTI+79SKCh+sAHaVrS5WTiEJgYrVhyZ+Vmdp56Bjn/4dCWIn2/me3QExjYLVHp6olS0IrJfnkpo8w9VQxfAc73ucDIh+V+mKEwtOCp0mNljvZUOe683nLIqxnpaeU9Qj0z1ZpL5qiD9hMy5sCWBSqjds885HJF0Ry7Mm8sl9qHXKIzKoriwClDpOcx/HPzKBFpOuUhzjvjfOuO5f/Irwhd8r6uqjG3pMjuGUF3Am8QMuQ1+TsscCeFVCKLbNv0k8Cfu8lCjphXiQke5vVkTVLHmRMskTFf3vZDXPA2+xNH5inzfLyyrPzuidDMEyozcWGzyV8SeZ1/1l9rn/Ai5x/Qx0iVehdAhwAAAABJRU5ErkJggg==",
    desc: "Dtalk数享会",
  },
  {
    anchor:'xmWiki',
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAA5FBMVEUAAABGUf8aJv9NVv8dKf81Qv81Qv9KU/9YYf81Pv8VH/9MU/8VHv8XJP88Rf81P/8uOf9ma/8HEv8vO/87Rf9UXP8nMv8FEf9ncP8DEP9WXv8VIP9OWf8cJ/9MVv8WHv9fZ/9CS/8LGP8iLf8gKv8iLP9mbf8/SP8sNv9GTv83QP9nc/9GT/9eZv8NGf9lbf9OVv8cKP8dK/8xPP8jLv82QP9CTP8tN/8oM/8fKv86RP8+SP9GT/9IUf88Rv8UIP9LU/9RWv9XX/9NVv8aJv9ka/9bY/8PG/9eZ/8LGP8IFP8EEf9JnXcSAAAAM3RSTlMAEhPw7wgO2BuCKiMb8e/v75GPc3Hv78KEg3l4Xl4qIu/v79nZ08W+vYJMKu/T07urq4MjJ0EuAAAB8klEQVRIx+3WaVfiMBQG4CSlC5RthkUFhHHcnVVblgqKLRS3//9/TDgV720TiP3s/fqeJzdwTt5T8jWaw5i5JRyU5al7MZn4/smVnB1W5o/PL3ulbFL0xmMhg1MrG1rHwXIu5OtBxg29d1lgmVu2fV8h+7dDtaT18eRD4tue36slrXkel0Ei92BmjqZKaVVveQB2mgA6I6U07dGUS7CzDKA7U0nWfJhxCXcOAKR3Wbk+uPzXWNylJPoDjKwMuqVSd34ThVzC21YInN6DRC758c0ISz84JGhshWyuhAS/85jgoYZcNp5WUQh2ti2iJ6/jp83O+6FXZ4Bsl/+EDJPb1igAO2QxTnbyrMqdvuysZcjlb+H0ZeFbIg3u8sgWd3nkD+7ySOHyyP2djrADqWQ73c/X77oSu+eXPJLx2s0jWWE5zyNZe1Oe3cUnJK2D2r3Ul7SGajfUlbSKa/e/prSOpriwG6FcWiloz1KF3Yjk8gS7Hi7PdS8qJK7HVrhIVf1l1On3OxJZgI7y0/HOyh9HBI6RlRRAd5WSfoMqX9kAQEcUGZSnpvp9lgE0Yyzr1paXjT5azmJY9aJ3lfKCwPkVg52id9WyhMP9eLPziDu1LMIASVv2gWS/y8Sh254J2eoR6VzZQp670tB0XHDLbErJ1+jNG3AqJryz2kQ9AAAAAElFTkSuQmCC",
    desc: "XM wiki",
  },
  {
    anchor:'insightLab',
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAABAlBMVEUAAABK0q8wqqEUmpMKkI4Fi4pCx60aoJY4vqdT17db37wPlI9c4b0PlJBU2bhZ3ro0t6QLkI0prZ4prp9d37xd474QlZFc4b1IzbEan5UPk49M0LNb37sNk40bopZR1rU1u6VAw6sGiYld47tO0LFe4r1T17c8wqolq50WmpNe4r0ytKIVmZIJjYtW27lGyq8boZdf4b4gppklqp8HjIxe47wUmpQhp5olqpsXmJFHzLAxt6NN07QUmZNCx601u6ZY3LoYnpVT17cts6Eorp8lq51e4r05vqggppo8wqoeo5gIjowQlZA+w6sNk44rsKAcoZcjqJtb4LxKz7JDyK5U2LdqcnwyAAAAOnRSTlMACgd1/UtjY1hJNjPf0M+dfGVkWFIVFfb08u3VvLiqbmREKCMd7+Dc2tnTmpSRjoOCeXZvb1xYTkVDX1MYbgAAAYtJREFUSMfN0tdSwkAUgOFNpEgH6V2KvXcNIQ0QQgBRw/u/imtC2Kxycc6FM36zzF79c/YwIX9BiOYiHXyWr2mOJrKLGJ6kgOmiMlNFdGJJ8rmChyGFA3/skclpgUOVlwav2OOdEag3XhocHo84z+AwPHFN3UsgYGV9Sek6/S31awIX6zMnBCM48yQEglKoW98CGYImxILBNvnfxNhTNpvdpVpdTHauLyej4aCnjueKVIqA/81K3wtNGn7KKeC8ymwTjp3QuASFFwEaTtehqUiSbGgdyEDLC9lEDfLYmLX49VTtA/KJbg1FaMh2dEPAZ962AtxEcEjYRGR4/2NHCRqSBG5HRkwgd2Qey8gdmcLLjusuXUWFJGN5AreHqHDB1JOIMNz3aRQRoe7XRIRTvwY83HvniPBwyEGEAw78qfu9DbWnqohwzIGHIdOcm3N63BsR2rZiK/TYDkQocURwGJE5BKxrGCtjRY9zJwlcXPPJIcLoKxMnGDebrpgnKLl1d0o7HOGhdhBPRcl2X7gTv1wDGTVzAAAAAElFTkSuQmCC",
    desc: "Insight LAB",
  },
];
