import React from 'react';
import { list } from './const';
import './ResearchPop.less'
interface Props {
    closePop?: (nav: string, anchor: string) => {}
}
const ResearchPop: React.FC<Props> = ({ closePop }) => {
    const linkHandle = (anchor: string) => {
        closePop?.('research', anchor)
    }
    return (
        <div className='researchpop_container'>
            <div className='researchpop_list'>
                {
                    list.slice(0, 2).map((item, index) => {
                        return <div
                            key={index} className='researchpop_list_item'
                            onClick={() => {
                                linkHandle(item.anchor)
                            }}
                        >
                            <i style={{ backgroundImage: `url(${item.icon})` }} />
                            <span>{item.desc}</span>
                        </div>
                    })
                }
            </div>
            <div className='researchpop_list'>
                {
                    list.slice(2, 3).map((item, index) => {
                        return <div key={index} className='researchpop_list_item'
                            onClick={() => {
                                linkHandle(item.anchor)
                            }}
                        >
                            <i style={{ backgroundImage: `url(${item.icon})` }} />
                            <span>{item.desc}</span>
                        </div>
                    })
                }
            </div>

        </div>
    );
};

export default ResearchPop;