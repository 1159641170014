import React from 'react';
import { Outlet } from 'react-router-dom';
import ActionBar from '../component/ActionBar/ActionBar';
import Footer from '../component/Footer/Footer';
import Header from './../component/Header/Header';
const Index = () => {
    return (
        <div style={{position:'relative',paddingTop:61,zIndex:999}}>
            <Header />
            <Outlet />
            <Footer />
            <ActionBar />
        </div>
    );
}

export default Index;
