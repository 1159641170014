import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import routerData, { RouteItem } from './router';
import { Spin } from 'antd'
const Loading = () => {
    return <div style={{
        width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'
    }}>
        <Spin />
    </div >
}
interface Props {
    isH5: boolean
}
const DynamicRoutes = (props: Props) => {
    const mapRouteMethod = (data: RouteItem[]) => {
        return data.map(({ path, element, h5Element, children, index = false }, idx) => {
            const Component = props.isH5 ? (typeof h5Element !== 'string' ? h5Element : lazy(() => import(`../pages/${h5Element}`))) : (typeof element !== 'string' ? element : lazy(() => import(`../pages/${element}`)));
            if (children) {
                return <Route key={idx} path={path}
                    element={<Suspense fallback={<Loading />}><Component /></Suspense>}
                >
                    {mapRouteMethod(children)}
                </Route>
            }
            return <Route key={idx} index={index} path={path} element={<Suspense fallback={<Loading />}><Component /></Suspense>} />
        })
    }
    return <>
        <Suspense>
            <Routes>
                {mapRouteMethod(routerData)}
                <Route path="*" element={<Navigate to="/home" replace={true} />} />
            </Routes>
        </Suspense>
    </>

};

export default DynamicRoutes;