import React from 'react';
import { Outlet } from 'react-router-dom';

const H5Index = () => {
    return (
        <Outlet />
    );
}

export default H5Index;
